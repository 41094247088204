import { mapGetters } from "vuex";

export default {
	components: {
		EmptyTrashButton: () => ({
			component: import('@/components/Documents/Actions/Trash/EmptyTrashButton')
		}),
	},
	computed: {
		...mapGetters({
			trashedElements: 'documents/trashed',
		}),
		routeIsTrash: function() {
			return this.$route.params.folderHash === 'trash'
		},
	}
}
