<template>
	<v-menu v-model="doShowMenu" :position-x="xCorrected" :position-y="yCorrected" absolute offset-x offset-y>
		<v-list ref="context-menu">
			<AddFolderButton v-if="!conditionalDisplay.isMobileApp" v-model="currentFolder" list-tile-display :selected-elements="selectedElements" />
			<AddDocumentButton v-if="!conditionalDisplay.isMobileApp" list-tile-display :parent="selectedFolder" :selected-elements="selectedElements" />
			<OpenPreviewButton v-model="nodes" :selected-folder="selectedFolder" list-tile-display />
			<OpenPreviewButton v-if="!conditionalDisplay.isMobileApp" v-model="nodes" :selected-folder="selectedFolder" list-tile-display open-in-new-tab />
			<RenameButton open-with-event list-tile-display :value="nodes" />
			<PrintButton v-if="!conditionalDisplay.isMobileApp" v-model="nodes" list-tile-display />
			<DownloadButton v-model="nodes" list-tile-display />
			<ValidationButtons v-if="!conditionalDisplay.isMobileApp" v-model="nodes" list-tile-display />
			<RotateDocumentButtons v-if="!conditionalDisplay.isMobileApp" v-model="nodes" list-tile-display />
			<SignatureButtons v-if="!conditionalDisplay.isMobileApp" list-tile-display :value="nodes" />
			<NodeSurveyButtons v-if="!conditionalDisplay.isMobileApp" list-tile-display :value="nodes" />
			<CounterButton list-tile-display :value="nodes" />
			<DeleteButton v-model="nodes" list-tile-display open-with-event :selected-folder="selectedFolder" />
			<RestoreFromTrashButton list-tile-display :parent="selectedFolder" :value="nodes" />
			<EmptyTrashButton v-if="routeIsTrash" v-model="trashedElements" list-tile-display />
		</v-list>
	</v-menu>
</template>

<script>
import { mapState } from "vuex";
import DocumentsManagerModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentsManagerModuleGuard'
import TrashMixin from "@/mixins/Trash/TrashMixin";

export default {
	name: 'ContextMenu',
	components: {
		AddDocumentButton: () => ({
			component: import('@/components/Documents/Actions/Add/AddDocumentButton')
		}),
		AddFolderButton: () => ({
			component: import('@/components/Documents/Actions/Add/AddFolderButton')
		}),
		CounterButton: () => ({
			component: import('@/components/Documents/Counter/CounterButton')
		}),
		DeleteButton: () => ({
			component: import('@/components/Documents/Actions/Delete/DeleteButton')
		}),
		DownloadButton: () => ({
			component: import('@/components/Documents/Actions/Download/DownloadButton')
		}),
		NodeSurveyButtons: () => ({
			component: import('@/components/Documents/Actions/Survey/NodeSurveyButtons')
		}),
		OpenPreviewButton: () => ({
			component: import('@/components/Documents/Actions/Open/OpenPreviewButton')
		}),
		RenameButton: () => ({
			component: import('@/components/Documents/Actions/Rename/RenameButton')
		}),
		RestoreFromTrashButton: () => ({
			component: import('@/components/Documents/Actions/Trash/RestoreFromTrashButton')
		}),
		RotateDocumentButtons: () => ({
			component: import('@/components/Documents/Actions/Rotate/RotateDocumentButtons')
		}),
		SignatureButtons: () => ({
			component: import('@/components/Documents/Signature/SignatureButtons')
		}),
		ValidationButtons: () => ({
			component: import('@/components/Documents/Actions/Validation/ValidationButtons')
		}),
		PrintButton: () => ({
			component: import('@/components/Documents/Actions/Print/PrintButton')
		})
	},
	mixins: [
		DocumentsManagerModuleGuard,
		TrashMixin,
	],
	props: {
		x: {
			required: true,
			type: Number
		},
		y: {
			required: true,
			type: Number
		},
		selectedFolder: {
			type: Object,
			required: false,
			default: null
		},
		parentDomElement: {
			type: HTMLDivElement,
			required: false,
			default: null
		},
		value: {
			type: Array,
			required: false,
			default: () => []
		},
		showMenu: {
			type: Boolean,
			required: true
		}
	},
	data: function () {
		return {
			model: true,
			contextMenu: null,
			xOffset: 0,
			yOffset: 0
		}
	},
	computed: {
		...mapState({
			currentDialog: state => state.dialogs.current
		}),
		doShowMenu: {
			get: function () {
				return this.showMenu
			},
			set: function (value) {
				this.$emit('showupdated', value)
			}
		},
		nodes: {
			get: function () {
				return Array.isArray(this.value) ? this.value : [this.value]
			},
			set: function (newValue) {
				this.$emit('input', newValue)
			}
		},
		items: function () {
			return this.nodes?.length > 1 ? this.nodes : null
		},
		item: function () {
			return this.nodes?.length == 1 ? this.nodes[0] : null
		},
		xCorrection: function () {
			return this.contextMenu ? this.x + this.contextMenu.offsetWidth - window.screen.width : 0
		},
		yCorrection: function () {
			return this.contextMenu ? this.y + this.contextMenu.offsetHeight - window.screen.height : 0
		},
		xCorrected: function () {
			return this.x - this.xOffset
		},
		yCorrected: function () {
			return this.y - this.yOffset
		},
		selectedElements: function () {
			return this.nodes.filter(node => node.id != this.selectedFolder?.id)
		},
		currentFolder: {
			get: function () {
				return this.selectedFolder
			},
			set: function (value) {
				if (value) {
					this.$emit('selection-changed', value)
				}
			}
		}
	},
	watch: {
		currentDialog: function (newValue) {
			if (newValue) {
				this.doClose()
			}
		}
	},
	mounted: function () {
		this.parentDomElement?.addEventListener('scroll', this.doClose)
		this.contextMenu = this.$refs.contextMenu
	},
	updated: function () {
		this.xOffset = this.xCorrection
		this.yOffset = this.yCorrection
	},
	destroyed: function () {
		this.parentDomElement?.removeEventListener('scroll', this.doClose)
		//this.doClose()
	},
	methods: {
		getModuleEventsActionsMapping: function () {
			return [{ event: this.events.SELECTION_START, action: this.doClose }]
		},
		isSingleInstanceEventListener: function () {
			return true
		},
		doClose: function () {
			this.model = false
			this.$emit('close')
		}
	}
}
</script>
